<template>
	<!-- SHOW MAINCATEGORIES -->
	<div class="inline">
		<div
			v-for="entry in selectedMainFields"
			:key="entry.jobfield._id"
			class="border-color-blue-grey border rounded-md h-10 w-10 cursor-pointer mt-4 mr-3 inline-block"
			@click="$emit('click')"
		>
			<div class="flex justify-center relative h-full">
				<div
					class="absolute bg-color-purple text-color-white font-bold text-center rounded-full h-[15px] w-[15px] -right-2 -top-2 text-[10px]"
				>
					{{ entry.selected }}
				</div>
				<HokIcon
					:size="8"
					color="blue-grey"
					class="self-center"
					:name="`jobfields:${getIconById(entry.jobfield.identifier)}`"
				/>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import type { IAPIJobFieldTree } from '@hokify/common';
import { defineComponent } from 'vue';
import SelectJobFields from './SelectJobFields.vue';
import { getIconById } from '../helpers/icon';

export default defineComponent({
	name: 'SelectedJobFieldsOverview',
	extends: SelectJobFields,
	emits: ['click'],
	data() {
		return {
			getIconById
		};
	},
	computed: {
		selectedMainFields() {
			const selectedElements: { jobfield: IAPIJobFieldTree; selected: number }[] = [];
			if (this.jobFields?.length) {
				this.jobFields.forEach(jobfield => {
					const selected = this.cntSelectedMainfields(jobfield._id);
					if (selected > 0) {
						selectedElements.push({ jobfield, selected });
					}
				});
			}
			return selectedElements;
		}
	}
});
</script>
