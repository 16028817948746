<template>
	<div class="md:w-1/2 md:mx-auto flex-1">
		<Spinner v-if="loading" />
		<div class="tabs">
			<Tabs
				:options="{ defaultTabHash: searchMode }"
				class="fullwidth2tabsonphone md:shadow-lg md:rounded-lg md:mt-4"
				name="jobFilterSearchMode"
			>
				<Tab id="categories" name="Kategorie" class="p-4">
					<strong class="block mb-3">Ausgewählte Kategorie:</strong>
					<div class="flex">
						<SelectedJobFieldsOverview
							:user="user"
							:job-fields="jobFields"
							:selected-job-fields="selectedFields"
							@click="doShowInterests()"
						/>
						<button
							class="w-10 h-10 p-2 mt-4 flex justify-center items-center rounded-md bg-color-main border border-color-main cursor-pointer shrink-0"
							@click="doShowInterests()"
						>
							<HokIcon color="white" name="icon:add" :size="5" />
						</button>
					</div>
				</Tab>
				<Tab id="searchterm" name="Individuell">
					<div class="pt-4 px-4 mb-5">
						<strong>Begriff/Branche:</strong>
						<Autocomplete
							id="jobfilter-branch"
							v-model="searchterm"
							:value-promise="ontologySuggestion"
							:position-initial="true"
							name="searchterm"
							value-id="searchterm"
							question="Gib einen Begriff oder Branche ein"
							@blur="setSearchterm"
							@input="setSearchtermDelayed"
						>
							z.B. Einzelhandel, Kellner,...
						</Autocomplete>
					</div>
				</Tab>
			</Tabs>
		</div>
		<div class="md:shadow-lg md:rounded-lg px-4 pb-8 md:mt-4 md:pt-4 mb-24">
			<div class="mb-5">
				<b>Ort:</b>
				<LocationAutocomplete
					id="location"
					:autofocus="false"
					:resolve-geo-coords="resolveGeoCoords"
					:value="currentLocation"
					:current-location-button="true"
					question="In welchem Ort suchst du einen Job?"
					@input="setLocation"
					@change="$event => setLocation($event, true)"
				>
					z.B. Wien
				</LocationAutocomplete>
				<HokSelect
					data-cy="radius"
					:value="currentRadius"
					:value-promise="radius"
					label="Umkreis"
					choose-text="Umkreis"
					@input="setRadius"
				/>
			</div>
			<template v-if="possibleFilters && typeof possibleFilters === 'object'">
				<div
					v-for="filterGroup in Object.keys(possibleFilters)"
					:key="filterGroup"
					class="filter-group mb-4"
				>
					<p class="font-bold">{{ filterTypes[filterGroup] }}:</p>
					<label v-for="filter in possibleFilters[filterGroup]" :key="filter.filter">
						<HokCheckbox
							:id="filter.filter"
							v-model="filter.filter"
							:checked="selectedFilters.indexOf(filter.filter) !== -1"
							@change="changeSingleFilter"
						>
							{{ filter.title }}
						</HokCheckbox>
					</label>
				</div>
			</template>
			<div v-if="hasCompanyFilter" class="pb-12">
				<p
					class="inline"
					:class="{ 'text-color-purple': !possibleResults || possibleResults <= 0 }"
				>
					Du siehst aktuell nur Jobs von einer Firma. Wenn du möchtest, kannst du dir wieder die
				</p>
				<HokButton color="main" is-text @click="removeCompanyFilter"
					>Jobs von allen Firmen anzeigen</HokButton
				>
				<p
					class="inline"
					:class="{ 'text-color-purple': !possibleResults || possibleResults <= 0 }"
				>
					lassen.
				</p>
			</div>
		</div>
		<div
			class="shadow p-2 inset-x-0 bottom-0 fixed z-50 bg-color-white mx-auto"
			:class="{ 'flex justify-center space-x-4': !$isMobile.any }"
		>
			<HokButton
				color="main"
				fullwidth="mobile"
				:class="{ 'mb-2': $isMobile.any }"
				@click="$emit('set-job-filter', jobFilterObj)"
			>
				<slot :possible-results="possibleResults" :loading="loading" />
			</HokButton>
			<HokButton color="white-main" fullwidth="mobile" @click="resetFilters">
				Alle Filter zurücksetzen</HokButton
			>
		</div>
	</div>
</template>

<script lang="ts">
import { IAPIJobFieldTree, IAPIJobFilter, type IAPILoginUser } from '@hokify/common';
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import type { PropType } from 'vue';

import { buildJobFilterObject } from '../helpers/buildJobFilterObject';
import SelectedJobFieldsOverview from './SelectedJobFieldsOverview.vue';
import Autocomplete from './Autocomplete.vue';
import LocationAutocomplete from './LocationAutocomplete.vue';
import Tabs from './Tabs.vue';
import Tab from './Tab.vue';
import HokCheckbox from './HokCheckbox.vue';
import HokSelect from './HokSelect.vue';
import { radiusOptions } from '../helpers/radiusOptions';
import HokButton from './HokButton.vue';
import HokIcon from './HokIcon.vue';
import { EventBus } from '../eventbus';

export default defineComponent({
	name: 'JobFilter',
	components: {
		HokIcon,
		HokButton,
		SelectedJobFieldsOverview,
		HokCheckbox,
		Autocomplete,
		LocationAutocomplete,
		Tab,
		Tabs,
		HokSelect
	},
	emits: ['update', 'set-job-filter'],
	data() {
		const possibleFilters: any = null;
		const setSearchTermDelay = undefined as undefined | number;

		const {
			selectedFilters,
			selectedFields,
			searchMode,
			searchterm,
			currentLocation,
			currentRadius
		} = buildJobFilterObject(this.selected);

		const filterTypes = {
			question: 'Kriterien',
			category: 'Hauptkategorie',
			publish: 'Online seit',
			wage: 'Gehalt',
			joblevel: 'Erfahrung',
			jobtype: 'Anstellungsart',
			company: 'Unternehmen',
			educationlevel: 'Ausbildung',
			range: {
				years: 'Jahre',
				km: 'km'
			}
		};

		return {
			radiusSetByUser: undefined,
			selectedFilters,
			selectedFields,
			searchMode,
			searchterm,
			currentLocation,
			currentRadius,
			buildJobFilterObject,
			radiusOptions,
			loading: false,
			possibleFilters,
			possibleResults: -1,
			setSearchTermDelay,
			filterTypes,
			EventBus
		};
	},
	beforeUnmount() {
		this.EventBus.$off('set-job-fields');
		this.EventBus.$off('end-go-to');
	},
	computed: {
		topLevelDomain(): string | undefined {
			return this.$nuxt?.$userRootStore?.topLevelDomain;
		},
		jobFilterObj() {
			const location = Array.isArray(this.currentLocation)
				? { geo: this.currentLocation }
				: { address: this.currentLocation };

			const search =
				this.searchMode === 'categories'
					? { fieldIds: this.selectedFields }
					: { searchterm: this.searchterm };

			const calculatedRadius = this.radiusSetByUser
				? parseInt(this.radiusSetByUser, 10)
				: undefined;

			return {
				search,
				location,
				region: this.topLevelDomain,
				radius: calculatedRadius,
				filters: this.selectedFilters || []
			};
		},
		hasCompanyFilter() {
			return this.selectedFilters?.find(filter => filter.includes('company-'));
		}
	},
	created() {
		this.setFilters('initial');

		if (this.currentRadius) {
			if (this.radiusOptions.length < 5) {
				this.radiusOptions.unshift({ value: '0 km' });
			}
		}
	},
	methods: {
		radius() {
			return this.radiusOptions;
		},
		removeCompanyFilter() {
			this.selectedFilters = this.selectedFilters.filter(filter => !filter.includes('company-'));
			this.setFilters();
		},
		async doShowInterests() {
			const jobFields = markRaw(defineAsyncComponent(() => import('./SelectJobFields.vue')));
			try {
				await this.$page.push(
					jobFields,
					{
						flowDirection: 'popup',
						title: 'In welchem Bereich suchst du einen Job?',
						subTitle: 'Bitte wähle mindestens einen aus.',
						secondTitle: 'Welche Berufe sind in deinem Interesse?',
						selectedJobFields: this.selectedFields,
						jobFields: this.jobFields,
						user: this.user
					},
					{
						pageTitle: 'Kategorie auswählen',
						name: 'select-job-fields',
						done: () => {
							this.EventBus.$off('set-job-fields');
							this.EventBus.$off('end-go-to');
						}
					}
				);

				this.EventBus.$on('set-job-fields', fields => {
					this.searchMode = 'categories';
					this.selectedFields = fields;
					this.setFilters().catch(err => this.$nuxt.$errorHandler(err));
				});
				this.EventBus.$on('end-go-to', () => {
					this.$page.goBack();
				});
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
		},
		setSearchtermDelayed() {
			if (this.setSearchTermDelay) {
				window.clearTimeout(this.setSearchTermDelay);
			}

			this.setSearchTermDelay = window.setTimeout(() => {
				this.setSearchterm();
			}, 1000);
		},
		setSearchterm() {
			if (this.setSearchTermDelay) {
				window.clearTimeout(this.setSearchTermDelay);
			}

			if (this.searchterm) {
				this.searchMode = 'searchterm';
			}
			this.setFilters();
		},
		setLocation(location, reload) {
			this.currentLocation = location;
			if (reload) {
				this.setFilters();
			}
		},
		setRadius(event) {
			if (event.value === '0 km') {
				if (this.radiusOptions.length > 4) {
					this.radiusOptions.shift();
				}
				this.currentRadius = ''; // reset frontend value of select
			} else if (this.radiusOptions.length < 5) {
				this.currentRadius = event.value; // set frontend value of select
				this.radiusOptions.unshift({ value: '0 km' });
			}
			this.radiusSetByUser = event.value;
			this.setFilters();
		},
		changeSingleFilter(checked, e, value) {
			e.preventDefault();

			if (checked) {
				this.selectedFilters.push(value);
			} else {
				this.selectedFilters = this.selectedFilters.filter(selected => selected !== value);
			}

			this.setFilters('subfilter');
		},
		async setFilters(e?: any) {
			let initial = false;
			if (e === 'initial') {
				initial = true;
			} else if (e && typeof e.preventDefault === 'function') {
				e.preventDefault();
			}

			if (initial && this.possibleFilters) {
				return false;
			}

			this.loading = true;

			return this.getPossibleFilter(this.jobFilterObj)
				.then(({ possibleFilters, selectedFilters, possibleResults }) => {
					this.selectedFilters = [...selectedFilters];
					const myPossibleFilters = {};
					if (possibleFilters && possibleFilters.length > 0) {
						possibleFilters.forEach(filter => {
							if (!myPossibleFilters[filter.type]) {
								myPossibleFilters[filter.type] = [];
							}
							myPossibleFilters[filter.type].push(filter);
						});
						if (e === 'subfilter') {
							Object.keys(this.possibleFilters).forEach(pfKey => {
								this.possibleFilters[pfKey].forEach(pfS => {
									pfS.disabled = !myPossibleFilters[pfKey]?.some(possibleFilter => {
										const match = possibleFilter.filter === pfS.filter;
										if (match) {
											possibleFilter.isUsed = true;
										}
										return match;
									});
								});

								if (myPossibleFilters[pfKey]) {
									myPossibleFilters[pfKey].forEach(possibleNewFilter => {
										if (possibleNewFilter.isUsed) {
											return;
										}

										if (!this.possibleFilters[pfKey]) {
											this.possibleFilters[pfKey] = [];
										}

										// add new filters
										this.possibleFilters[pfKey].push(possibleNewFilter);
									});
								}
							});
						} else {
							this.possibleFilters = myPossibleFilters;
						}
					}
					this.possibleResults = possibleResults;
				})
				.catch(err => this.$nuxt.$errorHandler(err))
				.finally(() => {
					this.loading = false;
				});
		},
		onJobFilterObjUpdate() {
			this.$emit('update', this.jobFilterObj);
		},
		ontologySuggestion(term) {
			return this.$nuxt?.$valuesStore?.ontologySuggestion(term);
		},
		resolveGeoCoords(payload) {
			return this.$nuxt?.$locationStore?.resolveGeoCoords(payload);
		},
		resetFilters() {
			this.selectedFields = [];
			this.currentLocation = '';
			this.setRadius({ value: '0 km' });
			this.selectedFilters = [];
			this.searchterm = '';
			this.setFilters();
		}
	},
	props: {
		getPossibleFilter: { type: Function, required: true },
		selected: { type: Object as PropType<IAPIJobFilter>, default: () => {} },
		jobFields: { type: Array as PropType<IAPIJobFieldTree[]>, default: () => [] },
		user: { type: Object as PropType<IAPILoginUser>, default: () => {} }
	},
	watch: {
		jobFilterObj: [
			{
				handler: 'onJobFilterObjUpdate'
			}
		]
	}
});
</script>

<style scoped lang="scss">
.filter-group {
	> label {
		width: 50%;
		display: inline-block;
		padding: 0;
		line-height: 0;
		input {
			display: inline-block;
			vertical-align: middle;
			margin-top: -5px;
			margin-right: 5px;
		}
	}
}

.tabs {
	height: initial;
}
</style>
